import styles from './styles';
import pdfMaps from './pdf-maps';
import search from './search';
// import parcel from './parcel';

import { InfoIcon, StudentLifeIcon, HousingIcon, HealthcareIcon, AcademicIcon, AthleticIcon } from '../components/Sidebar/SidebarIcons';

export default {
  gmapsVersion: '3.55',
  googleMaps: {
    public: { apiKey: 'AIzaSyAfKIB4_tYzYr5bYpUDUIGkoxk8Ap53jnI' },
    internal: { apiKey: 'AIzaSyAfKIB4_tYzYr5bYpUDUIGkoxk8Ap53jnI' },
  },
  syncDataBucket: 'https://storage.googleapis.com/rutgers-campus-map-dev-public-sync',
  dataBucket: 'https://storage.googleapis.com/rutgers-campus-map-public-data',
  buildingImagesBucket: 'https://storage.googleapis.com/rutgers-building-images',
  busDataBucket: 'https://storage.googleapis.com/rutgers-campus-map-dev-bus-sync',
  assetsBucket: 'https://storage.googleapis.com/rutgers-assets',
  imageryBucket: 'https://storage.googleapis.com/rutgers-dev-imagery-2024-retile',
  mapSetup: {
    initial: {
      id: 0,
      title: 'New Jersey',
      position: { lat: 40.2488462127653, lng: -74.70583984375003 },
      zoom: 8,
      styles: styles.map,
    },
  },
  vectorLayers: [
    { layerId: 'districts', level: 'districts', onWithUtilities: true },
    { layerId: 'main', level: 'features' },
    { layerId: 'parcel', level: 'features', toggleable: true },
    { layerId: 'clickSelection', level: 'features', onWithUtilities: true },
  ],
  highlights: ['informationCenter', 'academic', 'studentLife', 'housing', 'athletic', 'healthCare'],
  destinations: [
    { id: 'informationCenter', title: 'Information Centers', Icon: InfoIcon },
    { id: 'academic', title: 'Academics', Icon: AcademicIcon },
    { id: 'studentLife', title: 'Student Life', Icon: StudentLifeIcon },
    { id: 'housing', title: 'Housing', Icon: HousingIcon },
    { id: 'healthCare', title: 'Health Care', Icon: HealthcareIcon },
    { id: 'athletic', title: 'Athletic Venues', Icon: AthleticIcon },
  ],
  // transloc: {
  //   baseUrl: 'https://transloc-api-1-2.p.rapidapi.com',
  //   options: {
  //     headers: {
  //       'x-rapidapi-host': 'transloc-api-1-2.p.rapidapi.com',
  //       'x-rapidapi-key': 'KFzBESfnaJmshyrsG5mGxbiTCe7sp1eRX9OjsnyPGGp3gfMeBp',
  //     },
  //   },
  // },
  busLinks: {
    newBrunswick: 'http://rudots.rutgers.edu/campusbuses.shtml',
    newark: 'http://rudots.rutgers.edu/nwktransport.shtml',
    camden: 'http://rudots.rutgers.edu/campusbuses-cam.shtml',
  },
  search,
  legends: {
    bike: ['bike_rack', 'bike_lane', 'shared_lane_markings', 'shared_use_path'],
  },
  zoomLevel: {
    state: { min: 8, max: 11 },
    districts: { min: 12, max: 13 },
    features: { min: 14, max: 22 },
    emergencyPhones: { min: 17, max: 22 },
    underConstruction: { min: 15, max: 22 },
  },
  styles,
  pdfMaps,
  // parcel,
};
