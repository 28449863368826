import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './store';
import App from './components/App';

import './styles/mapControls.css';

const axe = require('@axe-core/react');

if (process.env.REACT_APP_ENV !== 'production') {
  axe(React, ReactDOM, 1000);
}

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<Root />);
